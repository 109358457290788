<template>
    <div>
        <v-container style="width:200px; height: 200px">
            <v-row justify="center">
                <v-col cols="12" class="my-0 py-0">
                    <v-progress-circular size="24" width="3" indeterminate color="primary"></v-progress-circular>
                </v-col>
                <v-col cols="12" class="my-0 py-0 mt-2">
                    <span style="font-size:16px">Loading...</span>
                    
</v-col>
            </v-row>
        </v-container>
    </div>
    </template>
    <script>
export default {
    name: 'LoadingOverlay'
}
</script>