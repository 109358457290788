var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.params.value)?_c('div',[_c('v-chip',{staticStyle:{"font-size":"11px"},attrs:{"x-small":"","color":_vm.params.value == '5+'
          ? 'redPop'
          : _vm.params.value == '3-4'
          ? '#dfaa49'
          : _vm.params.value == '0-2'
          ? 'tertiary'
          : _vm.params.value == 'past'
          ? 'component'
          : _vm.params.value == 'Arriving'
          ? 'blue'
          : ''}},[_vm._v(" "+_vm._s(_vm.params.value)+" "),(_vm.params.value != 'past' && _vm.params.value != 'Arriving')?_c('span',{staticClass:"ml-1"},[_vm._v(" Days")]):_vm._e()])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }