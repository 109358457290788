<template>
  <div>
    <v-card flat>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4" ld="3" v-if="!$vuetify.breakpoint.mobile">
          <v-text-field
            hide-details
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="search"
            outlined
            dense
            rounded
            clearable
          ></v-text-field>
        </v-col>

        <v-btn @click="$emit('close')" icon color="redPop" v-if="isDialog"
          ><v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="false"
      :rowGroupPanelShow="'always'"
      @grid-ready="onGridReady"
      :autoSizeStrategy="{
        type: 'fitGridWidth',
        defaultMinWidth: 125,
        columnLimits: [
          {
            colId: 'documentationStatus',
            minWidth: 120,
            width: 120,
          },
          {
            colId: 'fileNumber',
            minWidth: 180,
            width: 180,
          },
          {
            colId: 'document',
            minWidth: 180,
            width: 180,
          },
          {
            colId: 'vesselVoyage',
            minWidth: 200,
          },
          {
            colId: 'shipperName',
            minWidth: 200,
          },
          {
            colId: 'onBehalfShipperName',
            minWidth: 200,
          },
          {
            colId: 'consigneeName',
            minWidth: 200,
          },
          {
            colId: 'kpiCategory',
            minWidth: 50,
          },
        ],
      }"
      style="width: 100%; height: 90vh"
      :defaultColDef="defaultColDef"
      :enableRangeSelection="true"
      :suppressAggFuncInHeader="true"
      :getContextMenuItems="getContextMenuItems"
      :columnDefs="columns"
      :rowData="documents"
      :allowContextMenuWithControlKey="true"
    ></ag-grid-vue>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import OrganisationCell from "../GridComponents/Organisation.vue";
import UserCell from "../GridComponents/User.vue";
export default {
  props: {
    documents: {
      type: Array,
      required: true,
    },
    isDialog: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AgGridVue,
    OrganisationCell,
    UserCell,
  },
  data: () => ({
    columns: [],
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enableValue: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    gridApi: null,
    search: null,
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  created() {
    this.columns = [
      {
        headerName: "File No.",
        field: "fileNumber",
        aggFunc: "count",
      },
      {
        headerName: "Vessel/Voyage",
        field: "vesselVoyage",
      },
      {
        headerName: "Rejected",
        field: "rejected",
        aggFunc: "sum",
        cellStyle: (p) => {
          return { color: "var(--v-danger-lighten1) !important" };
        },
        onCellClicked: (p) => {
          console.log(p);
          if (p.column && p.column.colId) {
            console.log(p.data.fileNumber, p.column.colId);
            this.$emit("filterTable", {
              fileNumber: {filterType: "set", values:  [p.data.fileNumber]},
              status: {filterType: "set", values: ["Rejected"]},
            });
          }
        },
      },
      {
        headerName: "Overdue",
        field: "overdue",
        aggFunc: "sum",
        cellStyle: (p) => {
          return { color: "var(--v-redPop-lighten1) !important" };
        },
        onCellClicked: (p) => {
          console.log(p);
          if (p.column && p.column.colId) {
            console.log(p.data.fileNumber, p.column.colId);
            this.$emit("filterTable", {
              fileNumber: {filterType: "set", values: [p.data.fileNumber]},
              kpiCategory: {filterType: "set", values:  ["OVERDUE"]},
            });
          }
        },
      },
      {
        headerName: "Danger",
        field: "inDanger",
        aggFunc: "sum",
        cellStyle: (p) => {
          return { color: "#dfaa49 !important" };
        },
        onCellClicked: (p) => {
          console.log(p);
          if (p.column && p.column.colId) {
            console.log(p.data.fileNumber, p.column.colId);
            this.$emit("filterTable", {
              fileNumber: {filterType: "set", values: [p.data.fileNumber]},
              kpiCategory: {filterType: "set", values: ["DANGER"]},
            });
          }
        },
      },
      {
        headerName: "Within KPI",
        field: "withinKPI",
        aggFunc: "sum",
        cellStyle: (p) => {
          return { color: "var(--v-tertiary-base) !important" };
        },
        onCellClicked: (p) => {
          console.log(p);
          if (p.column && p.column.colId) {
            console.log(p.data.fileNumber, p.column.colId);
            this.$emit("filterTable", {
              fileNumber: {filterType: "set", values: [p.data.fileNumber]},
              kpiCategory: {filterType: "set", values: ["GOOD"]},
            });
          }
        },
      },
      {
        headerName: "Customer",
        field: "customerName",
        cellRenderer: "OrganisationCell",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
                name: p.data.customer.name,
                alias: p.data.customer.alias,
                logo: p.data.customer.logo,
                friendlyName: p.data.customer.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationCell",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                name: p.data.shipper.name,
                alias: p.data.shipper.alias,
                logo: p.data.shipper.logo,
                friendlyName: p.data.shipper.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationCell",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                name: p.data.consignee.name,
                alias: p.data.consignee.alias,
                logo: p.data.consignee.logo,
                friendlyName: p.data.consignee.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "ETD",
        field: "etd",
      },
      {
        headerName: "ETA",
        field: "eta",
      },
    ];
  },
  methods: {
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "Open File",
            icon: '<span class="ag-icon ag-icon-arrows" unselectable="on" role="presentation"></span>',
            action: () => {
              if (
                params.node &&
                params.node.data &&
                params.node.data.shipment.shipmentReference
              ) {
                this.$router.push({
                  path: "/shipment/" + params.node.data.shipment.shipmentReference
                });
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          {
            name: "Open File (New)",
            icon: '<span class="ag-icon ag-icon-arrows" unselectable="on" role="presentation"></span>',
            action: () => {
              if (
                params.node &&
                params.node.data &&
                params.node.data.shipment.shipmentReference
              ) {
                this.$router.push({
                  path: "/documentation/" + params.node.data.shipment.shipmentReference,
                });
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = ["copy", "copyWithHeaders", "export", "chartRange"];
      }

      return result;
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
  },
};
</script>