<template>
  <div>
    <div v-if="params.value">
      <v-chip
        x-small
        :color="
          params.value == '5+'
            ? 'redPop'
            : params.value == '3-4'
            ? '#dfaa49'
            : params.value == '0-2'
            ? 'tertiary'
            : params.value == 'past'
            ? 'component'
            : params.value == 'Arriving'
            ? 'blue'
            : ''
        "
        style="font-size: 11px"
      >
        {{ params.value }} <span class="ml-1" v-if="params.value != 'past' && params.value != 'Arriving'"> Days</span>
      </v-chip>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.rejected {
  color: var(--v-danger-base) !important;
}
.overdue {
  color: var(--v-redPop-lighten1) !important;
}
.inDanger {
  color: #dfaa49 !important;
}
.withinKPI {
  color: var(--v-tertiary-base) !important;
}
</style>