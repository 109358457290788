var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-spacer'),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"cols":"12","sm":"4","ld":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","prepend-inner-icon":"search","placeholder":"Search","outlined":"","dense":"","rounded":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.isDialog)?_c('v-btn',{attrs:{"icon":"","color":"redPop"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("close")])],1):_vm._e()],1)],1),_c('ag-grid-vue',{staticStyle:{"width":"100%","height":"90vh"},attrs:{"groupDisplayType":"multipleColumns","animateRows":false,"rowGroupPanelShow":'always',"autoSizeStrategy":{
      type: 'fitGridWidth',
      defaultMinWidth: 125,
      columnLimits: [
        {
          colId: 'documentationStatus',
          minWidth: 120,
          width: 120,
        },
        {
          colId: 'fileNumber',
          minWidth: 180,
          width: 180,
        },
        {
          colId: 'document',
          minWidth: 180,
          width: 180,
        },
        {
          colId: 'vesselVoyage',
          minWidth: 200,
        },
        {
          colId: 'shipperName',
          minWidth: 200,
        },
        {
          colId: 'onBehalfShipperName',
          minWidth: 200,
        },
        {
          colId: 'consigneeName',
          minWidth: 200,
        },
        {
          colId: 'kpiCategory',
          minWidth: 50,
        },
      ],
    },"defaultColDef":_vm.defaultColDef,"enableRangeSelection":true,"suppressAggFuncInHeader":true,"getContextMenuItems":_vm.getContextMenuItems,"columnDefs":_vm.columns,"rowData":_vm.documents,"allowContextMenuWithControlKey":true},on:{"grid-ready":_vm.onGridReady}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }