<template>
  <div>
    <div v-if="item">
      <v-tooltip top color="success" v-if="item.completedDate">
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            class="primaryText--text"
            small
            outlined
            style="border: none; font-size: 11px"
          >
            {{ item.completedDate }}
          </v-chip>
        </template>
        <span>Date of Completion</span>
      </v-tooltip>
      <v-tooltip top v-if="!item.completedDate">
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            class="primaryText--text"
            small
            outlined
            style="border: none; color: #5f5f5f !important; font-size: 11px"
          >
            {{ item.completedDue }}
          </v-chip>
        </template>
        <span>Completion Due Date</span>
      </v-tooltip>
    </div>
    <div v-else-if="group">
      {{ group }}
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {},
    group: null,
  }),
  mounted() {
    this.item = this.params.data;
    if (this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {},
};
</script>